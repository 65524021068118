<template>
  <div class="mb-5">
    <div class="d-flex justify-content-between align-items-center">
      <div style="width: 55vw" class="d-flex flex-column align-items-center">
        <div class="titre w-50 p-0">
          TRAITEUR ÉVÉNEMENTIEL HAUT DE GAMME À PARIS
        </div>
        <div class="contenu w-50 p-0 text-justify">
          Pour une réception responsable dans la fraicheur de l’air du temps
        </div>
      </div>
      <div style="width: 50vw">
        <img src="../assets/Assiette.png" alt="" class="w-100" />
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <EvenementType
        titre="Gastronomie"
        contenu="Cuisine de haute volée, un twist végétal, créée chaque saison par nos
        chefs"
      />
      <EvenementType
        titre="Pendant votre événement"
        contenu="Service expert, scénographies modernes et élégantes"
      />
      <EvenementType titre="Pour la planète" contenu="Réception à impact positif en écho à vos engagements RSE*"/>
    </div>
  </div>
</template>

<script>
import EvenementType from "./Utils/EvenementType.vue";
export default {
  components: { EvenementType },
};
</script>

<style>
.titre {
  color: #22b49d !important;
  font-family: "Rufina", Georgia, "Times New Roman", serif;
  font-weight: 500;
  font-size: 18px;
}
.contenu {
  font-family: "Rufina", Georgia, "Times New Roman", serif;
  font-size: 55px;
  text-align: justify;
}
</style>