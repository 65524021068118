<template>
  <div>
    <div class="container-lg d-flex align-items-center">
      <div class="w-25">
        <img src="../assets/fleur-de-mets.png" alt="logo" class="w-100 p-5" />
      </div>
      <div class="w-75">
        <div class="d-flex justify-content-end align-items-center mb-3">
          <div class="d-flex justify-content-center align-items-center">
            <i class="bi bi-envelope fs-3 border-none rounded-circle px-2"></i
            ><span class="mx-2">Nous contacter</span>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <i class="bi bi-telephone fs-3 border-none rounded-circle px-2"></i
            ><span class="mx-2">+33 1 85 17 05 00</span>
          </div>
          <div>
            <select class="form-select">
              <option value="2"><a href="#"><img src="../assets/fr.png" alt="fr"/></a></option>
              <option value="3"><img src="../assets/eng.png" alt="eng"/></option>
            </select>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <ul class="d-flex justify-content-between align-items-center list-unstyled w-75 px-5 py-0 my-0 mx-2 text-uppercase fs-7">
                <li>La Maison</li>
                <li>Lieux</li>
                <li>Prestation</li>
                <li>References</li>
                <li>Blog</li>
                <li>Engagement</li>
            </ul>
            <div class="d-flex align-items-center" style="width:15vw !important; margin-left:3vw"><button class="btn border w-100 px-5 py-2 text-uppercase">Demandeun devis</button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap-icons/font/bootstrap-icons.css";
export default {};
</script>

<style>
</style>