<template>
  <div class="d-flex my-4 justify-content-between mb-5" width="auto">
    <CardEvenement
      titre="GRANDS ÉVÉNEMENTS"
      contenu="Fleur de Mets accompagne les organisateurs de grands rendez-vous internationaux (sportifs, culturels, loisirs...) avec une palette complète de solutions de restaurations dédiées aux équipes, exposants, clients, mécènes, et visiteurs."
      images="https://cdn.fleurdemets.com/wp-content/uploads/2022/07/grand-evenement-2-scaled-e1657536229722.jpg"
      flex="reverse"
    />
    <CardTitleEvenement
      titre="Votre univers sur mesure"
      image="../../assets/Coeur_Abricot_basilic-683x1024-1.png"
      flex="reverse"
    />
    <CardEvenement
      titre="Cocktails"
      contenu="Célébrer, remercier, réunir, clôturer, le cocktail se prête à tous vos événements d’entreprise et permet le partage et l’interaction entre vos convives"
      images="https://cdn.fleurdemets.com/wp-content/uploads/2022/06/Entre%E2%95%A0ue_Carpaccio_St_Jacques_Chou_Fleur.jpg"
    />
    <CardEvenement
      titre="Séminaires et congrès"
      contenu="Séminaire, brainstorming, réunion, convention ? Selon votre objectif, le profil ou le nombre de vos participants, Fleur de Mets vous conseille et vous accompagne tout au long de vos évènements de travail avec une offre de restauration saine et gourmande conçue pour rassembler, ressourcer, faire plaisir…"
      images="https://cdn.fleurdemets.com/wp-content/uploads/2022/04/Ref-img-3.png"
    />
  </div>
</template>

<script>
import CardEvenement from "./Utils/CardEvenement.vue";
import CardTitleEvenement from "./Utils/CardTitleEvenement.vue";
export default {
  components: { CardEvenement, CardTitleEvenement },
};
</script>

<style>
</style>