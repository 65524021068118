<template>
  <div class="d-flex justify-content-center my-4">
    <div class="border" :style="`height:${ h }vh; width:1px`"></div>
  </div>
</template>

<script>
export default {
    props:{
        h:String
    }
}
</script>

<style>

</style>