<template>
  <div class="container-1" :style="`background:url('${bg}')`">
    <div class="container-2 p-5 d-flex flex-column justify-content-center align-items-center">
      <p class="w-50 fs-4 text-center titre-card-lieu">ATELIER DES LUMIÈRES</p>
      <p class="w-75 text-center lh-lg contenu-card-lieu">
        L’Atelier des Lumières est le lieu incontournable dédié à l’art
        numérique. Plongez dans l’art et la matière à travers plus de 200
        projections laser et personnalisez vos événements en créant des
        habillages totalement sur-mesure. Une expérience unique garantie !
      </p>
      <a href="" class=" fs-4 mt-2 text-decoration-none lien-card-lieu">> EN SAVOIR PLUS</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bg: String,
  },
};
</script>

<style>
.container-1 {
  width: 500px;
  height: 700px;
}

.text-justify {
    text-align: justify !important;
    text-justify: inter-word !important;
  }
.container-1::after {
  content: "Test";
  position: absolute;
}

.titre-card-lieu{
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
}

.contenu-card-lieu{
    font-family: "Raleway", Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 500;
    width: 60% !important;
}

.container-2 {
  background: url("https://dev-fleurdemets.trypl.com/wp-content/uploads/2022/05/Image.png");
  width: 550px;
  background-repeat: no-repeat;
  background-size: 550px 700px;
  height: 700px;
  opacity: 0;
}

.container-2:hover {
  opacity: 1;
}

.lien-card-lieu{
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    color: #00b092
}
</style>