<template>
  <div class="container-fluid">
    <Header/>
    <Video/>
    <LineSeparator h="10"/>
    <Evenement/>
    <LineSeparator h="15"/>
    <EvenementSlide/>
    <LieuReceptionVue/>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Video from '@/components/Video';
import LineSeparator from '@/components/Utils/LineSeparator';
import Evenement from '@/components/Evenement';
import EvenementSlide from './components/EvenementSlide.vue';
import LieuReceptionVue from '@/components/LieuReception';
export default {
  name: "App",
  components: {Header, Video, LineSeparator, Evenement, EvenementSlide,LieuReceptionVue},
};
</script>

<style>
</style>
