<template>
  <div style="width: 400px" class="mx-5 mb-5">
    <p class="h3 titre-ev text-center mb-3">{{ titre }}</p>
    <p class="fs-6 .contenu-ev text-center">
      {{ contenu }}
    </p>
  </div>
</template>

<script>

export default {
  
  props: {
    titre: String,
    contenu: String,
  },
};
</script>

<style>
.titre-ev {
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  font-size: 30px;
}

.contenu-ev {
  font-family: "Raleway", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 300;
}
</style>