<template>
  <div class="py-5">
    <div>
      <p class="text-center my-5 fs-6 title-lieu">LIEUX DE RÉCEPTION</p>
      <h3 class="text-center display-3 m-auto sub-title-lieu">
        À tout événement son lieu, son ambiance, son atmosphère
      </h3>
    </div>

    <div class="m-1 d-flex justify-content-center my-3">
      <img src="../assets/symbole-2-fonds-blanc.png" alt=""  />
    </div>
    <div>
      <p class="text-center fs-7  m-auto contenu-lieu"> 
        Moderne, classique ou franchement décalé, le choix d’un lieu donne le
        ton de votre événement, son ambiance révèle votre image et porte votre
        discours.
      </p>
    </div>
    <div>
        <CardLieu bg="https://cdn.fleurdemets.com/wp-content/uploads/2022/07/cocktail-klimt-3.jpg"/>
    </div>
  </div>
</template>

<script>
import CardLieu from './Utils/CardLieu.vue';
export default {
    components:{CardLieu}
};
</script>

<style>
.title-lieu, .contenu-lieu {
  font-family: "Raleway", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 600;
}
.sub-title-lieu {
  font-family: "Times New Roman", Times, serif;
  width: 600px;
  line-height: 1.5;
}

.contenu-lieu{
    width: 600px;
}

</style>