<template>
  <div class="cont-card d-flex " :style="`flex-direction:${flex=='reverse'?'column-reverse':'column'}`">
    <div><img :src="`${images}`" alt="test" class="w-100 "/></div>
    <div class="my-4">
        <p class="fs-5 text-uppercase titre-card">{{titre}}</p>
        <p class="contenu-card">{{contenu}}</p>
        <button class="btn border-dark w-50 btn-card rounded-none">Decouvrir</button>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        flex:String,
        titre:String,
        contenu:String,
        images:String
    }
};
</script>

<style>
.cont-card{
    width: 400px;
    height: 800px;
}

.titre-card{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 700;
}

.contenu-card{
    font-size: 13pt;
    text-align: justify;
    line-height: 1.6;
    font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif!important;
    font-weight: 700;
}

.btn-card{
    font-family: 'Raleway',Helvetica,Arial,Lucida,sans-serif!important;
    font-weight: 700 !important;
}
</style>