<template>
  <div
    class="cont-card d-flex"
    :style="`flex-direction:${flex == 'reverse' ? 'column-reverse' : 'column'}`"
  >
    <div>
      <img
        src="../../assets/Coeur_Abricot_basilic-683x1024-1.png"
        alt=""
        class="w-100"
        height="auto"
      />
    </div>
    <div class="m-1">
      <img src="../../assets/symbole-2-fonds-blanc.png" alt="" />
    </div>
    <div class="">
      <p class="m-0 text-uppercase titre-card-title">Votre univers sur mesure</p>
      <p class="m-0 sub-title-card">Quel univers recherchez-vous ?</p>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    flex: String,
    titre: String,
    contenu: String,
    image:String
  },
};
</script>
  
  <style>
.cont-card {
  width: 400px;
  max-height: 800px;
}

.titre-card-title {
  font-weight: 700;
  font-family: "Raleway", Helvetica, Arial, Lucida, sans-serif !important;
}

.sub-title-card {
  font-size:40px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

</style>