<template>
  <div class="d-flex ">
    <video controls  style="width: 80vw !important; margin:0 auto !important">
      <source src="../assets/FLEUR-DE-METS-LOW.mp4" type="video/mp4" style="width: 100% !important;" />
    </video>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>